











import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { Spinner } from '@components/UI';
import { ClipPost } from '@components/Posts';

@Component({
    components: { Spinner, ClipPost }
})
export default class Stream extends Vue {
    @Action
    public loadClipStream: () => Promise<any>;

    @Getter
    public clipStream: any;

    private loading: boolean = false;
    private observer: IntersectionObserver | null;

    public async mounted() {
        this.loadClipStream();
    }

    public beforeDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.$refs.trigger as HTMLElement);
            this.observer.disconnect();
            this.observer = null;
        }
    }

}

